<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <!--            <el-form-item label="">-->
            <!--              <el-button type="success" size="small" @click="addOption">添加</el-button>-->
            <!--            </el-form-item>-->
            <el-form-item label="" style="margin-left: 2vw">
              <el-input v-model="searchName" placeholder="请输入任务名称"></el-input>
            </el-form-item>
            <!-- <el-form-item style="margin-left: 5px">
              <el-date-picker v-model="value1" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
                              start-placeholder="开始日期" end-placeholder="结束日期"
                              :default-time="['12:00:00']" @change="changeTime"></el-date-picker>
            </el-form-item> -->
          </el-form>
        </div>
        <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button>
      </div>
    </div>
    <el-table
        v-loading="loading"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 1vh;">
      <el-table-column prop="id" label="序号" align="center"></el-table-column>
      <el-table-column prop="taskName" label="任务名称" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="deviceNumber" label="设备编号" show-overflow-tooltip align="center"></el-table-column>    
      <el-table-column prop="taskTime" label="任务时间" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="command" label="指令来源" show-overflow-tooltip align="center"></el-table-column>    
      <!-- <el-table-column prop="" label="状态" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <p v-if="scope.row.taskStatus == 1">开启</p>
          <p v-else>暂停</p>
        </template>
      </el-table-column>  -->
      <el-table-column prop="time" label="时间" show-overflow-tooltip align="center"></el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value1: "",
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      ids: 0,
      searchName: '',
      direction: 'rtl',
      drawer: false,
      msgs: [],
      showData: [],
      addForm: {},
      rules: {
        taskName: [{required: true, message: "任务名称不可为空", trigger: ["blur", "change"]}],
        deviceNumber: [{required: true, message: "设备编号不可为空", trigger: ["blur", "change"]}],
        taskType: [{required: true, message: "任务类型不可为空", trigger: ["blur", "change"]}],
        command: [{required: true, message: "指令来源不可为空", trigger: ["blur", "change"]}],
      },
      taskTypeList: [{id: '1', name: '一次性任务'}, {id: '2', name: '每月任务'}, {id: '3', name: '每日任务'}],
      taskTime: '',
      // time:'',
      formTitle: "",
      messageBox: false,
      showFlag: false,
      value: true,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      timeList: {
        startTime: '',
        endTime: '',
      },

    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    changeTime() {
      //console.log(this.value1)
      if (this.value1 == null) {
        this.timeList.startTime = null;
        this.timeList.endTime = null;
      } else {
        // //console.log("----------",this.value1[0])
        // //console.log("----------",this.value1[1])
        this.timeList.startTime = this.value1[0]
        this.timeList.endTime = this.value1[1]
      }

    },
    //请求列表数据
    getList() {
      this.$get("/timeTaskManage/getTimeTaskHistory", {
        page: this.page,
        size: this.size,
        taskName: this.searchName,
        // deviceNumber: this.searchName,
        // ncode: this.searchName,
        // from: this.timeList.startTime,
        // to: this.timeList.endTime
      }).then((res) => {
        if (res.data.state == "success") {
          this.tableData = res.data.datas;
          this.total = res.data.count;
          //console.log("获取列表", this.tableData)
        }
        setTimeout(() => {
          // this.loading = false;
        }, 500);
      });
    },
// 搜索
    search() {
      //console.log(11111)
      this.page = 1
      this.getList();
    },

    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button, .el-button--info {
  margin-left: 10px !important;

}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
}
.title /deep/ .el-form-item__content, .el-date-editor, .el-range-editor, .el-input__inner,.el-date-editor--datetimerange{
  padding: 0px 10px!important;
}

</style>

